import React from 'react';
import { Link } from 'gatsby';

import './article-preview.scss';

export default ({ article }) => {
  if (!article) return <></>;

  return (
    <Link to={`/blog/${article.slug}`} className="blog-link">
      <div className="article-preview">
        <span className="article-preview-date">{article.publishDate}</span>
        <h3 className="article-preview-title">{article.title}</h3>
        <p
          className="article-preview-content"
          dangerouslySetInnerHTML={{
            __html: article.description.childMarkdownRemark.html,
          }}
        />
        <div className="article-preview-tag-wrapper">
          {article.tags &&
            article.tags.map(tag => (
              <span key={tag} className="article-preview-tag">
                {tag}
              </span>
            ))}
        </div>
      </div>
    </Link>
  );
};
