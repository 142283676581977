import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';

import './blog.scss';

import Layout from 'components/Layout';
import ArticlePreview from 'components/ArticlePreview';
import Hero from 'components/Hero';

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts = get(this, 'props.data.allContentfulBlogPost.edges');

    return (
      <Layout active="blog" location={this.props.location}>
        <Helmet title={siteTitle} />
        <section id="hero">
          <Hero showSocial={false} title="Blog" className="blog" />
        </section>

        <section className="recent">
          <h2 className="recent-headline">Recent articles</h2>
          <ul className="recent-list">
            {posts.map(({ node }) => {
              if (node.slug !== 'hello-world') {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                );
              }
              return <></>;
            })}
          </ul>
        </section>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
